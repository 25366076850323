import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { updateWidgetSettingsReducer } from "./widgetSettingsReducer";
import { updateBorrowerDetailsReducer } from "./borrowerDetailsReducer";
import { updateLoanDetailsReducer } from "./loanDetailsReducer";
import { updateWebSettingsReducer } from "./websettingsReducer";
import { updateMonoWidgetDataReducer } from "./monoWidgetReducer";
import { updateWebAggregatorSettingsReducer } from "./webAggregatorSettingsReducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "monoWidgetDataReducer",
    "webAggregatorSettingsReducer",
    "borrowerDetailsReducer",
  ], // Whitelist reducers you want to persist
};

const rootReducer = combineReducers({
  widgetSettingsReducer: updateWidgetSettingsReducer,
  borrowerDetailsReducer: updateBorrowerDetailsReducer,
  loanDetailsReducer: updateLoanDetailsReducer,
  webSettingsReducer: updateWebSettingsReducer,
  monoWidgetDataReducer: updateMonoWidgetDataReducer,
  webAggregatorSettingsReducer: updateWebAggregatorSettingsReducer,
});

// @ts-ignore
const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;

export type TRootState = ReturnType<typeof rootReducer>;
