import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import styles from "./RepayLoan.module.scss";
import closeIcon from "../../../../assets/web/close-square.svg";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { yupValidators } from "../../../../helpers/yupValidators";
import { useForm } from "react-hook-form";
import arrowLeft from "../../../../assets/web/arrow-left.svg";
import wallet from "../../../../assets/web/wallet-3.svg";
import arrowRight from "../../../../assets/web/arrow-right.svg";
import card from "../../../../assets/web/card.svg";
import addCircle from "../../../../assets/web/add-circle.svg";
import successSVG from "../../../../assets/web/success.svg";
import Loader from "../../../../components/Loader/Loader";
import { postData } from "../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { Button } from "antd";
import { CardProp } from "../../profile/card/addCard/AddCardForm";
import AddNewCard from "./AddNewCard";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../interfaces/redux-interface";
import {
  updateLoanDetailsAction,
  updateWebSettingsAction,
} from "../../../../redux/actions";
import { WalletDetails } from "../../../../interfaces/wallet";
import { useDirectDebitsApprovedAcct } from "../../../../custom-hooks/useDirectDebitsApprovedAcct";
import { appInsights } from "../../../../components/AppInsight/AppInsight";
import useFetchCards from "../../../../custom-hooks/useFetchCard";
import { MixPanel } from "../../../../helpers/mixpanel";

interface RepaymentProps {
  refetchOutstandingLoanBalance: () => void;
  walletDetails: WalletDetails;
  setCurrentLoanOptionStep: Dispatch<SetStateAction<number>>;
}

const schema = yup.object().shape({
  amount: yupValidators.amount,
});

function RepayLoan({
  refetchOutstandingLoanBalance,
  walletDetails,
  setCurrentLoanOptionStep,
}: RepaymentProps) {
  const [currentStep, setCurrentStep] = useState(0);
  const [repaymentValue, setRepaymentValue] = useState<number>();
  const [approvedDirectDebitAccounts, setApprovedDirectDebitAccounts] =
    useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [formattedValue, setFormattedValue] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [debitCard, setDebitCard] = useState<CardProp[]>([]);
  const data = localStorage.getItem("currentUser");
  const userInfo = data && JSON.parse(data);
  const location = useLocation();
  const dispatch = useDispatch();
  const loanDataStore = useSelector(
    (state: ReduxStoreModel) => state.loanDetailsReducer,
  );

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web  - (RepayLoan.tsx)",
      isLoggedIn: true,
    });

    MixPanel.track(`Page View: RepayLoan.tsx`, {
      page: "RepayLoan.tsx - (Web)",
    });
  }, []);

  const goBack = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const handleNextButton = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const { data: value } = useFetchCards({
    borrower_id: userInfo?.customerId,
    aggregator_id: userInfo?.aggregatorId,
    isWidget: false,
  });
  const {
    data: ApprovedDirectDebitData,
    isLoading: ApprovedDirectDebitLoading,
    error: ApprovedDirectDebitError,
  } = useDirectDebitsApprovedAcct({
    loanRef: loanDataStore?.loan_ref,
    clientRequest: true,
  });

  useEffect(() => {
    setDebitCard(value);
  }, [value, currentStep]);

  useEffect(() => {
    if (ApprovedDirectDebitData?.length === 1) {
      ApprovedDirectDebitData?.map((data: any) =>
        setApprovedDirectDebitAccounts(data?.id),
      );
    }
  }, [ApprovedDirectDebitLoading]);

  // console.log("Data:", loanData)

  const onSubmit = ({ amount }: { amount: string }) => {
    const amountWithoutComma = amount.replace(/,/g, "");
    const convertedToNumber = parseFloat(amountWithoutComma);
    setRepaymentValue(convertedToNumber);

    handleNextButton();
  };

  const handleRepaymentWithWallet = async () => {
    setCurrentStep(4);
    try {
      const response = await postData(apiEndpoints.repayloan, {
        aggregator_id: userInfo?.aggregatorId,
        borrower_id: userInfo.customerId,
        borrower_repayment_mode: "Wallet",
        loan_id: loanDataStore?.loanId,
        amount: repaymentValue,
      });

      if (response.status) {
        setSuccess(response?.message);
        MixPanel.track("Repay loan", {
          distinct_id: `${userInfo?.email}`,
          "amount paid": `${repaymentValue}`,
          "repayment mode": `wallet`,
          "repayment status": `repaid`,
        });
        setCurrentStep(5);
      } else {
        setError(response?.message);
        appInsights.trackException({
          exception: response.message,
          properties: {
            fileName: "Web - (RepayLoan.tsx)",
          },
        });

        MixPanel.track("Repay loan", {
          distinct_id: `${userInfo?.email}`,
          "amount paid": `${repaymentValue}`,
          "repayment mode": `wallet`,
          "repayment status": `Failed`,
        });
        setCurrentStep(6);
      }
    } catch (error) {
      setError(error.response?.data?.message ?? error.message);
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Web - (RepayLoan.tsx)",
        },
      });

      MixPanel.track("Repay loan", {
        distinct_id: `${userInfo?.email}`,
        "amount paid": `${repaymentValue}`,
        "repayment mode": `wallet`,
        "repayment status": `Failed: ${error.response.data?.message ?? error.message}`,
      });
      setCurrentStep(6);
    }
  };
  const handleRepaymentWithDirectDebit = async () => {
    setCurrentStep(4);
    try {
      const response = await postData(apiEndpoints.repayloan, {
        aggregator_id: userInfo?.aggregatorId,
        borrower_id: userInfo.customerId,
        loan_direct_debit_mandate_id: approvedDirectDebitAccounts,
        borrower_repayment_mode: "DirectDebit",
        loan_id: loanDataStore?.loanId,
        amount: repaymentValue,
      });

      if (response.status) {
        setSuccess(response?.message);
        MixPanel.track("Repay loan", {
          distinct_id: `${userInfo?.email}`,
          "amount paid": `${repaymentValue}`,
          "repayment mode": `direct debit`,
          "repayment status": `repaid`,
        });
        setCurrentStep(5);
      } else {
        setError(response?.message);
        appInsights.trackException({
          exception: response.message,
          properties: {
            fileName: "Web - (RepayLoan.tsx)",
          },
        });

        MixPanel.track("Repay loan", {
          distinct_id: `${userInfo?.email}`,
          "amount paid": `${repaymentValue}`,
          "repayment mode": `direct debit`,
          "repayment status": `Failed`,
        });
        setCurrentStep(6);
      }
    } catch (error) {
      setError(error.response?.data?.message ?? error.message);
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Web - (RepayLoan.tsx)",
        },
      });

      MixPanel.track("Repay loan", {
        distinct_id: `${userInfo?.email}`,
        "amount paid": `${repaymentValue}`,
        "repayment mode": `direct debit`,
        "repayment status": `Failed: ${error.response.data?.message ?? error.message}`,
      });

      setCurrentStep(6);
    }
  };

  async function handleRepaymentWithCard() {
    if (debitCard?.length === 0) {
      setCurrentStep(3);
    } else {
      setCurrentStep(4);
      try {
        const response = await postData(apiEndpoints.repayloan, {
          aggregator_id: userInfo?.aggregatorId,
          borrower_id: userInfo.customerId,
          borrower_repayment_mode: "Card",
          loan_id: loanDataStore?.loanId,
          amount: repaymentValue,
        });

        if (response.status) {
          setSuccess(response?.message);
          MixPanel.track("Repay loan", {
            distinct_id: `${userInfo?.email}`,
            "amount paid": `${repaymentValue}`,
            "repayment mode": `card`,
            "repayment status": `repaid`,
          });
          setCurrentStep(5);
        } else {
          setError(response?.message);
          appInsights.trackException({
            exception: response.message,
            properties: {
              fileName: "Web - (RepayLoan.tsx)",
            },
          });
          MixPanel.track("Repay loan", {
            distinct_id: `${userInfo?.email}`,
            "amount paid": `${repaymentValue}`,
            "repayment mode": `card`,
            "repayment status": `Failed`,
          });
          setCurrentStep(6);
        }
      } catch (error) {
        setError(error.response?.data?.message ?? error.message);
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "Web - (RepayLoan.tsx)",
          },
        });
        MixPanel.track("Repay loan", {
          distinct_id: `${userInfo?.email}`,
          "amount paid": `${repaymentValue}`,
          "repayment mode": `card`,
          "repayment status": `Failed: ${error.response.data?.message ?? error.message}`,
        });
        setCurrentStep(6);
      }
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    // Allow digits and at most one decimal point
    const value = e.target.value.replace(/[^0-9.]/g, "");

    const parts = value.split(".");
    let formattedValue;
    if (parts.length > 2) {
      // More than one decimal point, ignore this input
      formattedValue = parts[0] + "." + parts[1];
    } else {
      formattedValue = value;
    }

    let formattedAmount: string;
    if (formattedValue === "") {
      formattedAmount = "";
    } else {
      const [integerPart, decimalPart] = formattedValue.split(".");
      if (decimalPart !== undefined) {
        formattedAmount =
          new Intl.NumberFormat("en-US").format(Number(integerPart)) +
          "." +
          decimalPart;
      } else {
        formattedAmount = new Intl.NumberFormat("en-US").format(
          Number(integerPart),
        );
      }
    }

    // Validation: Ensure the value is not below 1
    const numericValue = Number(formattedAmount.replace(/,/g, ""));
    if (numericValue < 1 && formattedAmount !== "") {
      formattedAmount = "1"; // Set the minimum value to 1
    }

    setFormattedValue(formattedAmount);
  };

  const handleClose = () => {
    document.body.style.overflow = "auto";

    dispatch(updateLoanDetailsAction({ loan_ref: "" }));
    if (location.pathname === `/${userInfo?.aggregator_name}/loan`) {
      refetchOutstandingLoanBalance();
    }

    dispatch(updateWebSettingsAction({ showLoanRepaymentModal: false }));

    setCurrentLoanOptionStep(0);
  };

  const renderContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <div className={styles.stepOne_container}>
            <header className={styles.stepOne_header}>
              <h3>Loan Repayment</h3>
              <p>Enter the amount you would like to pay</p>
            </header>

            <form
              className={styles.stepOne_form}
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className={styles.input_container}>
                <div className={styles.input_label_container}>
                  <label>Amount</label>
                  <input
                    type="text"
                    placeholder="₦"
                    name="amount"
                    ref={register}
                    className={`${styles.input_field}
                            }`}
                    value={formattedValue}
                    onChange={handleChange}
                  />
                </div>
                <span>{errors.amount?.message}</span>
              </div>

              <button className={styles.submit_button} type="submit">
                Proceed
              </button>
            </form>
          </div>
        );
      case 1:
        return (
          <div className={styles.stepTwo_container}>
            <img
              src={arrowLeft}
              alt=""
              className={styles.backArrow}
              onClick={goBack}
            />
            <header className={styles.stepTwo_header}>
              <h3>Payment Method</h3>
              <p>Select a payment method to proceed</p>
            </header>

            <div className={styles.role}>
              <div
                className={styles.wallet}
                onClick={() => setCurrentStep((prev) => prev + 1)}
              >
                <div className={styles.group1}>
                  <div className={styles.walletContainer}>
                    <img src={wallet} alt="" />
                  </div>
                  <div className={styles.text}>
                    <p>Pay with wallet</p>
                    <p>₦{walletDetails?.availableBalance?.toLocaleString()}</p>
                  </div>
                </div>
                <img src={arrowRight} alt="" />
              </div>
              {ApprovedDirectDebitData?.length > 0 && (
                <div
                  className={styles.wallet}
                  onClick={() => setCurrentStep(7)}
                >
                  <div className={styles.group1}>
                    <div className={styles.walletContainer}>
                      <img src={wallet} alt="" />
                    </div>
                    <div className={styles.text}>
                      <p>Pay with Direct Debit</p>
                      <p>{""}</p>
                    </div>
                  </div>
                  <img src={arrowRight} alt="" />
                </div>
              )}

              <div className={styles.card} onClick={handleRepaymentWithCard}>
                <div className={styles.group2}>
                  <div className={styles.cardContainer}>
                    <img src={card} alt="" />
                  </div>
                  <div className={styles.text}>
                    <h4 className={styles.onlyChild}>Pay with existing card</h4>
                  </div>
                </div>
                <img src={arrowRight} alt="" />
              </div>
            </div>

            <button
              className={styles.addCard}
              onClick={() => setCurrentStep((prev) => prev + 2)}
            >
              <img src={addCircle} alt="" />
              <span>Pay with a new card</span>
            </button>
          </div>
        );
      case 2:
        return (
          <div className={styles.stepThree_container}>
            <img
              src={arrowLeft}
              alt=""
              className={styles.arrow}
              onClick={goBack}
            />

            <header className={styles.stepThree_header}>
              <h3>Pay with wallet</h3>
              <p>
                You are about to make your loan repayment of ₦
                {repaymentValue?.toLocaleString()} using your Advancly Wallet
              </p>
            </header>

            <div className={styles.loanAmount_container}>
              <span className={styles.title}>Wallet Balance</span>
              <span className={styles.credit_balance}>
                ₦
                <strong>
                  {walletDetails?.availableBalance?.toLocaleString()}.
                </strong>
                00
              </span>

              <small style={{ marginTop: "10px" }}>Fee: 0.00</small>
            </div>

            <div className={styles.submit__btn}>
              <Button
                type="primary"
                htmlType="submit"
                onClick={handleRepaymentWithWallet}
              >
                Confirm
              </Button>
            </div>
          </div>
        );
      case 3:
        return (
          <div className={styles.stepFour_container}>
            <img
              src={arrowLeft}
              alt=""
              className={styles.backArrow}
              onClick={() => setCurrentStep(1)}
            />
            <AddNewCard
              updatePayStackStatus={() => null}
              handleRepaymentWithCard={handleRepaymentWithCard}
            />
          </div>
        );

      case 4:
        return (
          <div className={styles.stepFive_container}>
            <Loader message="Checking transaction status..." />
          </div>
        );
      case 5:
        return (
          <div className={styles.stepSix_container}>
            <div className={styles.verification_success}>
              <img src={successSVG} alt="" />
              <h3>Repayment Successful</h3>
              <p>{success}</p>
            </div>
          </div>
        );
      case 6:
        return (
          <div className={styles.stepSeven_container}>
            <div className={styles.verification_failed}>
              <div className={styles.box}></div>
              <h3>Repayment Failed</h3>
              <p>{error}</p>
            </div>
          </div>
        );

      case 7:
        return (
          <div
            style={{
              width: "100%",
              paddingTop: "50px",
            }}
          >
            <img
              src={arrowLeft}
              alt=""
              className={styles.backArrow}
              onClick={() => setCurrentStep(1)}
            />
            <h5>Choose a Direct Debit account</h5>
            {ApprovedDirectDebitError && (
              <p className="text-danger ">
                Error fetching approved direct debit accounts
              </p>
            )}
            {ApprovedDirectDebitLoading ? (
              <Loader message="Loading..." />
            ) : (
              ApprovedDirectDebitData?.map((debitDetails: any) => {
                const {
                  customer_bank_account_number,
                  customer_bank_account_name,
                  customer_bank_name,
                  amount: directAmount,
                  direct_debit_mandate_status,
                  id,
                } = debitDetails;
                return (
                  <>
                    <div
                      key={id}
                      className="d-flex flex-column align-items-start justify-content-start my-3"
                      onClick={() => {
                        setApprovedDirectDebitAccounts(id);
                      }}
                      style={{
                        backgroundColor:
                          approvedDirectDebitAccounts === id
                            ? "#713efe"
                            : "#f7f5ff",
                        border:
                          approvedDirectDebitAccounts === id
                            ? "1px solid #713efe"
                            : "1px solid #f7f5ff",
                        borderRightColor:
                          approvedDirectDebitAccounts === id
                            ? "#cfbefe"
                            : "#f7f5ff",
                        borderRightWidth:
                          approvedDirectDebitAccounts === id ? "5px" : "1px",

                        borderRadius: "10px",
                        padding: "10px",
                        cursor: "pointer",
                        color: approvedDirectDebitAccounts === id ? "#fff" : "",
                      }}
                    >
                      <div
                        className=""
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {" "}
                        <span className="mr-1">Account Number: {""} </span>
                        <span>{""}</span>
                        <span style={{ fontWeight: "bold" }}>
                          {customer_bank_account_number}
                        </span>
                      </div>
                      <div className="d-flex justify-content-end">
                        <span className="mr-1">Account Name: {""} </span>
                        <span style={{ fontWeight: "bold" }}>
                          {customer_bank_account_name}
                        </span>
                      </div>
                      <div className="d-flex justify-content-end">
                        <span className="mr-1">Bank Name: {""}</span>
                        <span style={{ fontWeight: "bold" }}>
                          {customer_bank_name}
                        </span>
                      </div>
                      <div className="d-flex justify-content-end">
                        <span className="mr-1">Amount: {""}</span>
                        <span style={{ fontWeight: "bold" }}>
                          {directAmount}
                        </span>
                      </div>
                      <div className="d-flex justify-content-end">
                        <span className="mr-1">Mandate Status: {""}</span>
                        <span style={{ fontWeight: "bold" }}>
                          {direct_debit_mandate_status}
                        </span>
                      </div>
                    </div>
                  </>
                );
              })
            )}

            <div className={styles.submit__btn}>
              <Button
                type="primary"
                htmlType="submit"
                onClick={handleRepaymentWithDirectDebit}
              >
                Confirm
              </Button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className={styles.overlay}></div>
      <div className={styles.modal_container}>
        <div className={styles.modal_steps_wrapper}>
          {renderContent()}

          <img
            src={closeIcon}
            alt=""
            className={styles.cancel_button}
            onClick={handleClose}
          />
        </div>
      </div>
    </>
  );
}

export default RepayLoan;
